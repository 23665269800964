import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService, BreakpointObserverService, CustomTranslationService, EUserRole, MenuEntry } from 'shared';
import { Consts } from '../../consts';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  public isHomePage$ = new BehaviorSubject(true);
  public mainMenu: MenuEntry[] = [
    {
      route: Consts.eventsRoute,
      translationKey: 'header.menuItems.events'
    } as MenuEntry,
    {
      route: Consts.signUpRoute,
      translationKey: 'header.menuItems.signUp'
    } as MenuEntry,
    {
      route: Consts.informationRoute,
      translationKey: 'header.menuItems.information'
    } as MenuEntry,
    {
      route: Consts.aboutRoute,
      translationKey: 'header.menuItems.about'
    } as MenuEntry,
    {
      route: Consts.contactRoute,
      translationKey: 'header.menuItems.contact'
    } as MenuEntry,
  ];

  public userMenu: MenuEntry[];

  private subscription = new Subscription();

  constructor(public authService: AuthService, public breakpointObserverService: BreakpointObserverService, private router: Router, public customTranslationService: CustomTranslationService) {
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.mainMenu.forEach(m => {
          if (m.route === '/') {
            m.selected = this.router.url === '/';
            if (m.selected) {
              this.isHomePage$.next(true);
            }
          } else {
            m.selected = this.router.url.startsWith(`/${m.route}`);
            if (m.selected) {
              this.isHomePage$.next(false);
            }
          }
        });
      }
    });

    this.subscription.add(this.authService.userChanged$.subscribe(_ =>{
      this.userMenu = this.getUserMenu();
    }));
  }

  private getUserMenu(): MenuEntry[] {
    let userMenuEntries = [
      {
        translationKey: 'header.menuItems.userProfile',
        route: Consts.userInfoRoute
      } as MenuEntry,
      // {
      //   translationKey: 'header.menuItems.clubs',
      //   route: Consts.userClubsRoute
      // } as MenuEntry,
      {
        translationKey: 'header.menuItems.myActiveEvents',
        route: Consts.userEventsRoute
      } as MenuEntry,
      {
        translationKey: 'header.menuItems.sponsoringEvents',
        route: Consts.userSponsorLinksRoute
      } as MenuEntry
    ];

    if (this.authService.hasRole(EUserRole.CompanyAdmin)) {
      userMenuEntries.push(...[
        {
          translationKey: 'header.companyAdmin.events',
          route: Consts.adminEventsRoute,
          subEntries: []
        } as MenuEntry,
        {
          translationKey: 'header.companyAdmin.clubs',
          route: Consts.adminClubsRoute,
          subEntries: []
        } as MenuEntry,
        {
          translationKey: 'header.companyAdmin.companies',
          route: Consts.adminCompaniesRoute,
          subEntries: []
        }
      ]);
    }

    if (this.authService.hasRole(EUserRole.GlobalAdmin)) {
      userMenuEntries.push(...[{
        translationKey: 'header.menuItems.admin',
        route: Consts.adminRoot
      } as MenuEntry]);
    }

    return userMenuEntries;
  }

  public async signIn() {
    await this.authService.signIn()
  }

  public logout() {
    this.authService.signOut();
  }

  public getToday(): Date {
    return new Date();
  }

  public menuEntryActive(route: string): boolean {
    // if (this.router.isActive(route, false)) {
    //    gitdebugger;
    // }
    // console.log(this.router.url);
    return this.router.isActive(route, true);
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
