import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { RegisterActivityComponent } from './components/register-activity/register-activity.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { SponsorUserSignupComponent } from './components/sponsor-user-signup/sponsor-user-signup.component';
import { CompanyCreateEventComponent } from './components/company-create-event/company-create-event.component';
import {
  apiUrlToken,
  AuthInterceptor,
  ContentProviderInterceptor,
  contentProviderToken,
  frontendUrlToken,
  LoadingInterceptor,
  SharedModule,
  oidcClientIdToken
} from 'shared';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { environment } from '../environments/environment';
import { CompanySearchComponent } from './components/company-search/company-search.component';
import { BecomeSponsorComponent } from './components/become-sponsor/become-sponsor.component';
import { CreateEditClubComponent } from './components/create-edit-club/create-edit-club.component';
import { EventsComponent } from './components/events/events.component';
import { SponsorlinkComponent } from './components/sponsorlink/sponsorlink.component';
import { HeaderComponent } from './components/header/header.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { InformationComponent } from './components/information/information.component';
import { ContactComponent } from './components/contact/contact.component';
import { AboutComponent } from './components/about/about.component';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './components/home/home.component';
import { SponsorTermsComponent } from './components/sponsor-terms/sponsor-terms.component';
import { GdprComponent } from './components/gdpr/gdpr.component';
import { SignUpSucceededUserComponent } from './components/sign-up-succeeded-user/sign-up-succeeded-user.component';
import { EventTermsComponent } from './components/event-terms/event-terms.component';
import { ParticipateTermsComponent } from './components/participate-terms/participate-terms.component';
import { EditUserInfoComponent } from './components/edit-user-info/edit-user-info.component';
import { Consts } from './consts';
import { AdminComponent } from './components/admin/admin.component';
import { TabAdminClubValidateComponent } from './components/tab-admin-club-validate/tab-admin-club-validate.component';
import { TabAdminEventValidateComponent } from './components/tab-admin-event-validate/tab-admin-event-validate.component';
import { EventComponent } from './components/event/event.component';
import { CreateEditEventComponent } from './components/create-edit-event/create-edit-event.component';
import { UserInfoComponent } from './components/user-info/user-info.component';
import { ClubAdminClubsComponent } from './components/club-admin-clubs/club-admin-clubs.component';
import { ClubAdminEventsComponent } from './components/club-admin-events/club-admin-events.component';
import { UserEventsComponent } from './components/user-events/user-events.component';
import { UserClubsComponent } from './components/user-clubs/user-clubs.component';
import { SponsoringEventsComponent } from './components/sponsoring-events/sponsoring-events.component';
import { registerLocaleData } from '@angular/common';
import localDaDK from '@angular/common/locales/da-GL';
import { TabEventComponent } from './components/tab-event/tab-event.component';
import { TabCompanyDataComponent } from './components/tab-company-data/tab-company-data.component';
import { ToastComponent } from './components/toast/toast.component';
import { ModalComponent } from './components/modal/modal.component';
import { TabEventSponsorsComponent } from './components/tab-event-sponsors/tab-event-sponsors.component';
import { TabEventSponsorRunUsersComponent } from './components/tab-event-sponsor-run-users/tab-event-sponsor-run-users.component';
import { TabEventUserAdminComponent } from './components/tab-event-user-admin/tab-event-user-admin.component';
import { TabAdminInvalidClubsComponent } from './components/tab-admin-invalid-clubs/tab-admin-invalid-clubs.component';
import { TabEventInviteSponsorComponent } from './components/tab-event-invite-sponsor/tab-event-invite-sponsor.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { CdkStepperModule} from '@angular/cdk/stepper';
import { TabEventInviteParticipantComponent } from './components/tab-event-invite-participant/tab-event-invite-participant.component';
import { EventLinkComponent } from './components/event-link/event-link.component';
import { TabEventEditComponent } from './components/tab-event-edit/tab-event-edit.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TabEventRegisterActivityComponent } from './tab-event-register-activity/tab-event-register-activity.component';
import { UserRegisterActivityComponent } from './components/user-register-activity/user-register-activity.component';
import { UserEditSignupComponent } from './components/user-edit-signup/user-edit-signup.component';
import { Router } from '@angular/router';
import * as Sentry from "@sentry/angular";
import { TabAllSponsorRunUsersAdminComponent } from './components/tab-all-sponsor-run-users-admin/tab-all-sponsor-run-users-admin.component';
import { TabAllSponsorsAdminComponent } from './components/tab-all-sponsors-admin/tab-all-sponsors-admin.component';
import { TabEventLatestEventRegistrationsComponent } from './components/tab-event-latest-event-registrations/tab-event-latest-event-registrations.component';
import { SponsorPaymentComponent } from './components/sponsor-payment/sponsor-payment.component';
import { WaitingForPaymentComponent } from './components/waiting-for-payment/waiting-for-payment.component';
import { PaymentCompleteComponent } from './components/payment-complete/payment-complete.component';
import { SignUpSucceededEventComponent } from './components/sign-up-succeeded-event/sign-up-succeeded-event.component';
import { SignUpSucceededSponsorComponent } from './components/sign-up-succeeded-sponsor/sign-up-succeeded-sponsor.component';
import { PodiumComponent } from './components/podium/podium.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ResetPasswordLinkComponent } from './components/reset-password-link/reset-password-link.component';
import { UserSignUpComponent } from './components/user-sign-up/user-sign-up.component';
import { UserChangePasswordComponent } from './components/user-change-password/user-change-password.component';
import { CustomSentryErrorHandler } from './custom-sentry-error-handler';
import { CreateEditCompanyComponent } from './components/create-edit-company/create-edit-company.component';
import { CompaniesAdminCompaniesComponent } from './components/companies-admin-companies/companies-admin-companies.component';
import { TabAdminUsersComponent } from './components/tab-admin-users/tab-admin-users.component';
import { TabAdminUserComponent } from './components/tab-admin-user/tab-admin-user.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { InformationArrangeComponent } from './components/information-arrange/information-arrange.component';
import { InformationParticipateComponent } from './components/information-participate/information-participate.component';
import { InformationSponsorsComponent } from './components/information-sponsors/information-sponsors.component';
import { ClubTermsComponent } from './components/club-terms/club-terms.component';

registerLocaleData(localDaDK);

@NgModule({
  declarations: [
    AppComponent,
    RegisterActivityComponent,
    SignUpComponent,
    CompanyCreateEventComponent,
    SponsorUserSignupComponent,
    CompanySearchComponent,
    BecomeSponsorComponent,
    CreateEditClubComponent,
    EventsComponent,
    SponsorlinkComponent,
    HeaderComponent,
    InformationComponent,
    ContactComponent,
    AboutComponent,
    FooterComponent,
    HomeComponent,
    SponsorTermsComponent,
    GdprComponent,
    SignUpSucceededUserComponent,
    EventTermsComponent,
    ParticipateTermsComponent,
    EditUserInfoComponent,
    AdminComponent,
    TabAdminClubValidateComponent,
    TabAdminEventValidateComponent,
    EventComponent,
    CreateEditEventComponent,
    UserInfoComponent,
    ClubAdminClubsComponent,
    ClubAdminEventsComponent,
    UserEventsComponent,
    UserClubsComponent,
    SponsoringEventsComponent,
    TabEventComponent,
    TabCompanyDataComponent,
    ModalComponent,
    ToastComponent,
    TabEventSponsorsComponent,
    TabEventSponsorRunUsersComponent,
    TabEventUserAdminComponent,
    TabAdminInvalidClubsComponent,
    TabEventInviteSponsorComponent,
    TabEventInviteParticipantComponent,
    EventLinkComponent,
    TabEventEditComponent,
    TabEventRegisterActivityComponent,
    UserRegisterActivityComponent,
    UserEditSignupComponent,
    TabAllSponsorRunUsersAdminComponent,
    TabAllSponsorsAdminComponent,
    TabEventLatestEventRegistrationsComponent,
    SponsorPaymentComponent,
    WaitingForPaymentComponent,
    PaymentCompleteComponent,
    SignUpSucceededEventComponent,
    SignUpSucceededSponsorComponent,
    PodiumComponent,
    ResetPasswordComponent,
    ResetPasswordLinkComponent,
    UserSignUpComponent,
    UserChangePasswordComponent,
    CreateEditCompanyComponent,
    CompaniesAdminCompaniesComponent,
    TabAdminUsersComponent,
    TabAdminUserComponent,
    StepperComponent,
    InformationArrangeComponent,
    InformationParticipateComponent,
    InformationSponsorsComponent,
    ClubTermsComponent
  ],
  imports: [
    SharedModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ReactiveFormsModule,
    ClipboardModule,
    CdkStepperModule,
    NgbModule
  ],
  providers: [
    {
      provide: apiUrlToken,
      useValue: environment.apiUrl
    },
    {
      provide: frontendUrlToken,
      useValue: environment.frontendUrl
    },
    {
      provide: oidcClientIdToken,
      useValue: environment.oidcClientId
    },
    {
      provide: contentProviderToken,
      useValue: 'eventshop'
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ContentProviderInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: HttpErrorInterceptor,
    //   multi: true
    // },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: CustomSentryErrorHandler
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(trace: Sentry.TraceService) {}
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
