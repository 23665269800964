import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Navigation, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { Consts } from '../../consts';
import { FormViewComponent } from '../form-view/form-view.component';
import { UsersService, AuthService, SignUpUser } from 'shared';
import { ModalService } from '../../services/modal.service';
import { PasswordValidation, UsernameValidation, UtilService } from '../../services/util.service';

@Component({
  selector: 'app-user-sign-up',
  templateUrl: './user-sign-up.component.html',
  styleUrls: ['./user-sign-up.component.scss']
})
export class UserSignUpComponent extends FormViewComponent implements OnInit, AfterViewInit, OnDestroy {
  public userForm: FormGroup = new FormGroup({
    firstName: new FormControl(undefined, [Validators.required]),
    lastName: new FormControl(undefined, [Validators.required]),
    phoneNumber: new FormControl(undefined, [Validators.pattern("[0-9]{8}"), Validators.required]),
    email: new FormControl(undefined, [Validators.required, Validators.email]),
    address: new FormGroup({
      streetOne: new FormControl(undefined, [Validators.required]),
      streetTwo: new FormControl(undefined),
      postalCode: new FormControl(undefined, [Validators.required, Validators.pattern("[0-9]{4}")]),
      city: new FormControl(undefined, [Validators.required]),
    }),
    username: new FormControl(undefined, [Validators.required]),
    password: new FormControl(undefined, [Validators.required]),
    repeatPassword: new FormControl(undefined, [Validators.required]),
    gdpr: new FormControl(false, [Validators.requiredTrue])
  });

  public passwordValidation: PasswordValidation;
  public usernameValidation: UsernameValidation;

  private subscription = new Subscription();
  private readonly previousNavigation: Navigation;

  constructor(private usersService: UsersService, private authService: AuthService, private router: Router, protected elementRef: ElementRef, private modalService: ModalService, public utilService: UtilService) {
    super(elementRef);
    this.previousNavigation = this.router.getCurrentNavigation().previousNavigation;
    // this.subscription.add(this.authService.userChanged$.subscribe(u => {
    //   this.userForm.patchValue(u);
    // }));
  }

  ngOnInit(): void {
    this.userForm.controls.username.markAsTouched();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  async ngAfterViewInit() {
    super.ngAfterViewChecked();
  }

  public haveToCheckUsername() {
    this.userForm.controls.username.setErrors({});
  }

  public checkUsername() {
    if (!this.userForm.controls.username.value) {
      return;
    }
    this.usersService.checkUsername(this.userForm.value as SignUpUser)
      .pipe(first())
      .subscribe(r => {
        this.modalService.showModal('modal.usernameAvailableTitle', 'modal.usernameAvailableDesc');
        this.userForm.controls.username.setErrors(null);
      }, _ => {
        this.modalService.showModal('modal.usernameExistsTitle', 'modal.usernameExistsDesc');
        this.userForm.controls.username.setErrors({});
        this.userForm.controls.username.markAsTouched();
      });
  }

  public signUp() {
    if (this.userForm.valid && this.userForm.controls.password.value == this.userForm.controls.repeatPassword.value && this.utilService.passwordValid(this.passwordValidation)
      && this.utilService.usernameValid(this.usernameValidation)) {
      this.usersService.signUp(this.userForm.value as SignUpUser).pipe(first())
        .subscribe(_ => {
          this.authService.signIn();
        });
    } else {
      if (this.userForm.controls.password.value != this.userForm.controls.repeatPassword.value || !this.utilService.passwordValid(this.passwordValidation)) {
        this.userForm.controls.password.setErrors({});
        this.userForm.controls.repeatPassword.setErrors({});
      }

      if (!this.utilService.usernameValid(this.usernameValidation)) {
        this.userForm.controls.username.setErrors({});
      }

      this.userForm.markAllAsTouched();
      this.showError();
    }
  }

  public validatePassword() {
    this.passwordValidation = this.utilService.validatePassword(this.userForm.controls.password.value);
  }

  public validateRepeatedPassword() {
    if (this.userForm.controls.password.value != this.userForm.controls.repeatPassword.value) {
      this.userForm.controls.repeatPassword.setErrors({});
    } else {
      this.userForm.controls.repeatPassword.setErrors(null);
    }
  }

  public validateUsername() {
    this.usernameValidation = this.utilService.validateUsername(this.userForm.controls.username.value);
  }

  public getGdprRoute(): string {
    return `/${Consts.gdprRoute}`
  }
}
