<div class="container-fluid">
  <div class="menu-container" [class]="(isHomePage$ | async) ? '' : 'menu-drop-shadow '">
    <div class="nav-container container-fluid">
      <nav class="navbar navbar-expand-lg navbar-dark nav-wrapper">
        <div class="navbar-brand" routerLink="/">
          <img src="/assets/header/logo.png" width="133" height="59" alt="">
        </div>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon custom-navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNavDropdown">
          <ul class="navbar-nav">
            <li class="nav-item" *ngFor="let menuEntry of mainMenu">
              <a *ngIf="menuEntry.route" class="nav-link text-uppercase" [attr.data-bs-toggle]="(breakpointObserverService.ipad$ | async).matches ? 'collapse' : null" [attr.data-bs-target]="(breakpointObserverService.ipad$ | async).matches ? '#navbarNavDropdown' : null " [routerLink]="menuEntry.route"><span [class]="menuEntry.selected ? 'nav-link-active' : ''">{{menuEntry.translationKey | translate}}</span></a>
              <a *ngIf="menuEntry.entryAction" class="action-entry nav-link text-uppercase" [attr.data-bs-toggle]="(breakpointObserverService.max992$ | async).matches ? 'collapse' : null" [attr.data-bs-target]="(breakpointObserverService.ipad$ | async).matches ? '#navbarNavDropdown' : null " (click)="menuEntry.entryAction()">{{menuEntry.translationKey | translate}}</a>
            </li>
            <li *ngIf="!(authService.signedInChanged$ | async)" class="nav-item dropdown">
              <a class="nav-link text-uppercase" [attr.data-bs-toggle]="(breakpointObserverService.ipad$ | async).matches ? 'collapse' : null" [attr.data-bs-target]="(breakpointObserverService.ipad$ | async).matches ? '#navbarNavDropdown' : null " (click)="signIn()" [routerLink]="[]"><span>{{'header.menuItems.login' | translate}}</span></a>
            </li>
            <li *ngIf="(authService.signedInChanged$ | async)" class="nav-item dropdown">
              <a class="nav-link dropdown-toggle text-uppercase" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false" [attr.data-bs-target]="(breakpointObserverService.ipad$ | async).matches ? '#navbarNavDropdown' : null ">
                {{'header.menu' | translate }}
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                <li><a *ngFor="let menuEntry of userMenu" class="dropdown-item" [routerLink]="menuEntry.route">{{menuEntry.translationKey | translate}}</a></li>
                <li><a class="dropdown-item" (click)="logout()">{{'header.menuItems.signOut' | translate}}</a></li>
              </ul>
            </li>
            <li class="nav-item">
              <div class="nav-link date">
                <span class="align-middle text-uppercase">{{getToday() | date: 'EEE dd.LL.y' : 'IST' : customTranslationService.getLang() | titlecase}}</span>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</div>
